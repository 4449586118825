<template>
  <footer class="footer">
    <div class="col-grid">
      <div class='col-3 center icons'>
        <a :href="'https://www.facebook.com/'+this.$store.getters.company.socials.fb">
        <svg version="1.1" class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60.734px" height="60.733px" viewBox="0 0 60.734 60.733" style="enable-background:new 0 0 60.734 60.733;" xml:space="preserve">
          <g>
            <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
              v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
              v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"/></g>
        </svg>
        </a>
        <a :href="'https://www.instagram.com/'+this.$store.getters.company.socials.instagram">
          <svg class="icon" viewBox="0 0 512.00096 512.00096" xmlns="http://www.w3.org/2000/svg"><path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875 138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm-117.40625 395.996094c-77.195312 0-139.996094-62.800782-139.996094-139.996094s62.800782-139.996094 139.996094-139.996094 139.996094 62.800782 139.996094 139.996094-62.800782 139.996094-139.996094 139.996094zm143.34375-246.976563c-22.8125 0-41.367188-18.554687-41.367188-41.367187s18.554688-41.371094 41.367188-41.371094 41.371094 18.558594 41.371094 41.371094-18.558594 41.367187-41.371094 41.367187zm0 0"/><path d="m256 146.019531c-60.640625 0-109.980469 49.335938-109.980469 109.980469 0 60.640625 49.339844 109.980469 109.980469 109.980469 60.644531 0 109.980469-49.339844 109.980469-109.980469 0-60.644531-49.335938-109.980469-109.980469-109.980469zm0 0"/><path d="m399.34375 96.300781c-6.257812 0-11.351562 5.09375-11.351562 11.351563 0 6.257812 5.09375 11.351562 11.351562 11.351562 6.261719 0 11.355469-5.089844 11.355469-11.351562 0-6.261719-5.09375-11.351563-11.355469-11.351563zm0 0"/></svg>
        </a>
        <a :href="'https://www.vimeo.com/'+this.$store.getters.company.socials.vimeo">
          <svg version="1.1" class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="438.536px" height="438.536px" viewBox="0 0 438.536 438.536" style="enable-background:new 0 0 438.536 438.536;" xml:space="preserve">
            <g><path d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
                C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
                h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
                C438.532,59.576,430.49,40.204,414.41,24.123z M358.033,145.319c-2.673,13.894-10.52,35.454-23.562,64.665
                c-13.045,29.218-29.455,57.054-49.252,83.511c-22.654,30.453-46.445,53.009-71.374,67.673c-6.473,3.99-13.706,6.995-21.698,8.99
                c-7.994,1.995-16.086,1.852-24.269-0.432c-11.613-4.948-21.414-16.847-29.408-35.689c-3.997-9.712-9.423-28.459-16.274-56.246
                c-6.85-26.837-11.897-44.633-15.131-53.387c-0.95-2.477-2.141-6.332-3.567-11.564c-1.429-5.236-2.856-9.801-4.281-13.706
                c-1.427-3.903-3.186-7.564-5.282-10.992c-3.234-5.52-7.183-8.373-11.847-8.566c-4.661-0.188-10.185,0.81-16.558,2.996
                c-6.374,2.19-11.56,2.993-15.559,2.426c-2.281-0.38-4.327-2.283-6.136-5.709c-1.809-3.422-4.237-5.896-7.283-7.421v-1.999
                c7.613-5.333,19.271-15.037,34.973-29.127c15.703-14.085,28.693-24.172,38.971-30.262c13.324-7.804,25.41-10.185,36.26-7.139
                c5.137,1.524,9.945,5.33,14.416,11.42c4.471,6.091,7.85,12.658,10.135,19.701c2.096,6.66,5.33,22.266,9.707,46.822
                c0.572,3.805,1.384,10.61,2.428,20.411c1.045,9.803,1.999,17.795,2.853,23.982c0.855,6.188,2.664,13.135,5.426,20.838
                c2.756,7.713,6.324,13.853,10.704,18.421c8.186,8.754,16.464,10.089,24.838,3.997c5.332-3.997,12.088-13.421,20.273-28.26
                c8.945-17.316,13.894-30.928,14.839-40.825c1.334-14.465-1.191-23.885-7.563-28.265c-6.372-4.377-17.173-5.902-32.401-4.57
                c3.046-9.326,7.283-18.03,12.703-26.122c5.428-8.086,11.088-14.753,16.988-19.984c5.91-5.236,12.049-9.851,18.418-13.849
                c6.379-3.996,11.848-6.897,16.423-8.705c4.565-1.809,8.466-3,11.704-3.571c25.693-3.805,43.488,0.476,53.392,12.847
                C359.077,116.576,361.075,129.141,358.033,145.319z"/></g></svg>
        </a>
      </div>
      <div class='col-3 center'>
        <span style="text-transform:uppercase">© 2016 - 2022 
          <span class="bar">{{ this.$store.getters.company.name }} </span>
          </span>
        <!-- <br> -->
        <!-- <span class="mobile-hidden" style="text-transform:uppercase">{{ this.$store.getters.company.fullname }}</span> -->
      </div>
      <div class='col-3 center'>
        <template v-if="this.$store.getters.company.phone.length > 0"><a :href="'tel:'+this.$store.getters.company.phone">{{ this.$store.getters.company.phone }}</a><br></template>
        <a :href="'mailto:'+this.$store.getters.company.email">{{ this.$store.getters.company.email }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src
import fb from '../assets/socials/fb.svg'
import ig from '../assets/socials/ig.svg'
import vm from '../assets/socials/vm.svg'
export default {
  name: 'Footer',
  data() { return {
      fb, ig, vm
    }
  }
}
</script>
<style lang="scss" scoped>

footer {
  // background-color: rgb(12, 12, 12, 0.92);
  // padding-top: 50px;
  // color: #fff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}
.center {
  margin: 0 auto;
  min-height: 100px;
}
.center>a {
  text-decoration: none;
  // color: #fff;
  color: #707070;
}
.icons>a {
  border: none !important;
}
a>.icon {
  border: none !important;
  width: 25px;
  height: 25px;
  margin-left:10px;
  margin-right:10px;
  fill: rgb(189, 189, 189);
}
a>.icon:hover {
  fill: rgb(136, 136, 136);
}
@media screen and (max-width: 660px) {
  .icon {
    width: 16px !important;
    height: 16px !important;
  }
  footer {
    font-size: 0.7rem !important;
  }
}
.col-3 {
  display: flex;
  flex: 0 1 33%;
  justify-content: center;
  align-items: center;
  width: auto;
}
@media screen and (max-width: 450px) {
  .col-grid {
    flex-direction: column;
  }
  .center {
    min-height:1.5rem;
  }
  .bar {
    display:flex;
    justify-content: center;
    width: 100%;
  }
  .col-3 {
    flex: 0 1 100%;
    justify-content: center;
    padding-bottom:1.5rem;
  }
  footer {
    padding-top:1.5rem;
  }
}
</style>
