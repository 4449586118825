import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueYandexMetrika, {
  id:  40987949,
  router: router,
  env: process.env.NODE_ENV,
  // other options
  options: {
    clickmap:true, 
    trackLinks:true, 
    accurateTrackBounce:true, 
    webvisor:true, 
    trackHash:true
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
