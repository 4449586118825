<template>
    <div v-if="show" class="loader">
        <img class="image" :src="require('../assets/afslogo.svg')" alt="Audio Flag Studio">
        <div class="circles">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        }
    },
    mounted() {
        this.showToggle()
    },
    methods: {
        showToggle() {
            setTimeout(() => {
                this.show = false
            }, 2500);
        }
    }
}
</script>

<style scoped>
.loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left:0;
    top: 0;
    overflow: hidden;
    background-color: white;
    z-index: 9999;
}
.image {
    width: 40%;
    animation-name: scaleIn;
    animation-duration: 3s;
}
.circles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.circle {
    width: .8rem;
    height: .8rem;
    background-color: #353434;
    border-radius: 50%;
    margin-right: 1rem;
    animation: pulse 1.5s infinite;
    animation-name: scaleInCircles;
    /* animation-duration: 1s; */
    transition: 0.5s;
    transform: scale(0);
}
.circle:nth-child(1) {
    animation-delay: .1s;
}
.circle:nth-child(2) {
    animation-delay: .2s;
}
.circle:nth-child(3) {
    animation-delay: .3s;
}
.circle:nth-child(4) {
    animation-delay: .4s;
}
.circle:nth-child(5) {
    animation-delay: .5s;
}
@keyframes scaleIn {
    0% {
        opacity: .8;
        transform: scale(.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes scaleInCircles {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

</style>