<template>
  <div class="portfolio">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <Item :item="item"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Item from '@/components/Item.vue'

export default {
  name: 'Portfolio',
  components: {
    Item
  },
  computed: {
    item() {
      return this.$store.getters.works.find(a=> a.link === this.$route.params.id)
    },
  }
  
}
</script>
