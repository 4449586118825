<template>
  <div class="home">
    <h3 style="margin-bottom:3.5rem;">Leading sound design and music production studio</h3>
    <Work/>
  </div>
</template>

<script>
// @ is an alias to /src
import Work from '@/components/Work.vue'

export default {
  name: 'Home',
  components: {
    Work
  }
}
</script>
