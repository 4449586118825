<template>
<div class="container">
  <div v-if="link" class="block" 
  :style="'background-image:url('+getImgUrl(img)+');'"
  :class="size ? size:'default'">
  </div>
  <div class="hover">
      <a>{{ name }}</a>
    </div>
</div>
</template>

<script>
export default {
  name: 'Block',
  props: {
    size: String,
    name: String,
    img: String,
    link: String,
  },
  methods: {
    linkTo() {
      // this.$router.push('/portfolio/'+this.link).catch(err => {console.log(err)})
    },
    getImgUrl(pic) {
      return pic ? require('../assets/img/'+pic) : false
    },
  }
}
</script>

<style scoped lang="scss">
div {
  transition: all 0.8s ease-out;
}
.default {
  width:25%;
}
.big {
  width: 100%;
  display:block;
  background-size: 50% !important;
}
.square {
  width: 250px;
}
.container {
  display: flex;
  flex: 0 1 100%;
  position: relative;
}
.block {
  // display: inline-block;
  // min-height: 15rem;
  // max-height: 250px;
  // margin-right: 20px;
  // margin-bottom: 20px;

  display: flex;
  flex: 0 1 100%;
  min-height: 200px;
  // position: relative;
  z-index: 1;
  transform: scale(1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: content-box;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-name: bgScaleOut;
}
.block:hover {
  animation-name: bgScaleIn;
}
.hover {
  display: flex;
  flex: 0 1 100%;
  min-width: 100%;
  position: absolute;
  // min-height: 15rem;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  // height: 250px;
  height: 100%;
  transition: all 0.3s ease-out;
  opacity: 0;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  z-index: 99;
}
.hover:hover {
  opacity: .85;
  position: absolute;
  // width: 100%;
  background-color: #000000;
  z-index: 99;
  animation-name: opacityIn;
}
a {
  position: absolute;
  text-transform: uppercase;
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  font-weight: 700;
  color: #FFF;
  display: block !important;
  border: none;
  white-space: break-spaces;
}

@keyframes bgScaleOut {
  0% {
    // background-size: 150%;
    transform: scale(1.5) !important;
  }
  100% {
    // background-size: 130%;
    transform: scale(1) !important;
  }
}
@keyframes bgScaleIn {
  0% {
    // background-size: 130%;
    transform: scale(1) !important;
  }
  100% {
    // background-size: 150%;
    transform: scale(1.5) !important;
  }
}
@media screen and (max-width: 1024px) {
  .default {
    width:45%;
  }
  .block {
    padding-right: 0px;
  }
  .big {
    width:100%;
    background-size: 50% !important;
  }
}
@media screen and (max-width: 660px) {
  .default {
    width:100%;
  }
  .big {
    width:100%;
    background-size: 120% !important;
  }
  .block {
    margin-bottom: .5rem;
  }
}
@keyframes opacityIn {
  0% {
    opacity: .85;
    // font-size: 1rem
  }
  100% {
    opacity: .99;
    // font-size: 1.5rem
  }
}

</style>
