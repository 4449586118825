<template>
  <div class="item" >
    <h2><router-link to="/" style="color: #fff; margin-right:.2rem;"><span class="undo" alt="Back to main page"/> Porfolio</router-link> <span class="round" style=" margin-right:.2rem;"></span> {{item.name}}</h2>
    <div class="grid" style="padding-bottom:40px;">
      <iframe :src="item.url" 
      width="640" 
      height="360" 
      frameborder="0" 
      allow="autoplay; fullscreen" 
      allowfullscreen>
      </iframe>
    </div>
    <div class="grid">
      {{item.desc}}
      <br>
      Author: {{item.author}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Item',
  props: {
    item: Object,
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
iframe {
  max-width: 100%;;
}
.round {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  display: inline-block;
  background-color: #2c3e50;
  vertical-align: middle;
}
.undo {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' fill='white' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M142.716,293.147l-94-107.602l94-107.602c7.596-8.705,6.71-21.924-1.995-29.527c-8.705-7.596-21.917-6.703-29.527,1.995 L5.169,171.782c-6.892,7.882-6.892,19.65,0,27.532l106.026,121.372c4.143,4.729,9.94,7.157,15.771,7.157 c4.883,0,9.786-1.702,13.755-5.169C149.427,315.071,150.319,301.852,142.716,293.147z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M359.93,164.619H20.926C9.368,164.619,0,173.986,0,185.545c0,11.558,9.368,20.926,20.926,20.926H359.93 c60.776,0,110.218,49.441,110.218,110.211S420.706,426.893,359.93,426.893H48.828c-11.558,0-20.926,9.368-20.926,20.926 c0,11.558,9.368,20.926,20.926,20.926H359.93c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width:15px;
  height: 15px;
  padding-right:.2rem;
  padding-bottom:.05rem; 
  opacity: .8; 
  fill: #fff;
}
</style>
