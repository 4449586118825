<template>
  <div class="work">
    <div class="grid">
      <router-link v-for="(v,k) in this.$store.getters.works" :key="k" class="link" :style="v.width ? v.width : ''" :to="`portfolio/${v.link}`">
      <template>
        <Block v-if="v.url" :name="v.name" :img="v.img" :size="v.size" :link="v.link"/>
      </template>
      </router-link>
    </div>
  </div>
</template>

<script>
import Block from './Block.vue'

export default {
  name: 'Work',
  components: {
    Block,
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.grid {
  // display: inline-block;
  // width: 90vw;
  // margin: 0 auto;

  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  // flex-shrink: 1;
}
.link {
  display: flex;
  flex: 0 1 250px;
  min-height: 200px;
  max-height: 200px;
  flex-grow: 1 !important;
  border:none !important;
  position: relative;
  overflow: hidden;
  
  &:first-of-type {
    // flex: 0 1 100%;
    background-color: #fff;
  }
  &:nth-of-type(3) {
    flex: 0 1 35%;
    min-height: 200px;
  max-height: 200px;
  }
  &:nth-of-type(7) {
    flex: 0 1 30%;
    min-height: 200px;
  max-height: 200px;
  }
  &:nth-of-type(5n) {
    flex: 0 1 503px;
    min-height: 200px;
  max-height: 200px;
  }
  &:last-of-type {
    flex: 0 1 25%;
    min-height: 200px;
  max-height: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .grid {
    // width: 100vw;
  }
}
h3 {
  margin: 40px 0 0;
}
li {
  display: inline;
  list-style: none;
}
</style>
