<template>
  <div class="packs" style="min-height: 70vh;">
    <li v-for="(v,k) in this.$store.getters.samplepacks" :key="k">
      <Block :size="v.size" :name="v.name" :img='v.img' :link="v.link"/>
    </li>
  </div>
</template>

<script>
// @ is an alias to /src
import Block from '@/components/Block.vue'

export default {
  name: 'Packs',
  components: {
    Block,
  }
}

</script>
<style lang="scss" scoped>
li {
  list-style: none;
  display:inline-block;
}
</style>