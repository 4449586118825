import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    company: {
      fullname: 'Audio Flag',
      name: 'Audio Flag',
      email: 'dmitry@audioflag.com',
      phone: '',
      socials: {
        fb: 'audioflag',
        instagram: 'audioflag',
        vimeo: 'audioflag',
        youtube: 'audioflag',
        vk: 'audioflag',
      }
    },
    samplepacks: [
      {
        name: 'Dark Hypnotic Techno',
        link: 'dht',
        size: 'square',
        img: 'darktechno.jpg',
      },
      {
        name: 'Neuro Jump Up',
        link: 'nju',
        size: 'square',
        img: 'neurojumpup.jpg',
      },
      {
        name: 'Deep Tech House',
        link: 'dth',
        size: 'square',
        img: 'deeptechhouse.jpg',
      },
      {
        name: 'Complex Neurofunk',
        link: 'cn',
        size: 'square',
        img: 'complexneuro.jpg',
      },
    ],
    works: [
      {
        name: 'Carbon Core VFX Studio',
        link: 'carboncore',
        size: '',
        img: 'carboncore.png',
        url: 'https://player.vimeo.com/video/667246114',
        desc: 'Sound Design for Carbon Core VFX Studio',
        tags: 'sound design',
        author: 'Audio Flag, Carbon Core',
      },
      {
        name: 'Into the Music (Documentary)',
        link: 'intothemusic',
        size: '',
        img: 'intothemusic.png',
        url: 'https://player.vimeo.com/video/641677371',
        desc: 'Mixing and Mastering for Documentary film by OGON.ME. Directed by Sasha Guschin',
        tags: 'mixing, mastering',
        author: 'Audio Flag, OGON.ME',
      },
      {
        name: 'ECCO Shoes',
        link: 'eccoshoes',
        size: '',
        width: 'flex: 0 1 120px',
        img: 'eccoshoes.png',
        url: 'https://player.vimeo.com/video/667266808',
        desc: 'Music for ECCO Russia',
        tags: 'sound design, advert, music',
        author: 'Audio Flag, ECCO Russia',
      },
      {
        name: 'Ooletk by Ghost3D',
        link: 'ooletk',
        width: 'flex: 0 1 150px',
        size: '',
        img: 'ooletk.png',
        url: 'https://player.vimeo.com/video/667464884',
        desc: 'Meet the Ooletk. Sound design & exclusive music for another great viral video by ghost3d',
        tags: 'sound design, music',
        author: 'Audio Flag, ghost3d',
      },
      {
        name: 'New Hyundai Creta',
        link: 'creta2021',
        size: '',
        img: 'creta.png',
        url: 'https://vimeo.com/audioflag?embedded=true&source=owner_name&owner=58804612',
        desc: 'Music for Hyundai Russia',
        tags: 'advert, music',
        author: 'Audio Flag, Hyundai, Alex Ricochet',
      },
      {
        name: 'LOVE DEATH + ROBOTS',
        link: 'lovedeathrobots',
        size: '',
        img: 'netflix.png',
        url: 'https://youtube.com/embed/AUaXtfAFva8',
        desc: 'Soundtrack for NETFLIX LOVE DEATH + ROBOTS',
        tags: 'music',
        author: 'Audio Flag, Gancher & Ruin, Netflix',
      },
      {
        name: 'ECCO Leather',
        link: 'eccoleather',
        size: '',
        img: 'ecco.png',
        url: 'https://player.vimeo.com/video/667265525',
        desc: 'Music for ECCO Russia',
        tags: 'sound design, advert, music',
        author: 'Audio Flag, ECCO Russia',
      },
      {
        name: 'MINI Russia 2021',
        link: 'mini',
        size: '',
        img: 'mini.png',
        url: 'https://youtube.com/embed/jTE7-5zREA0',
        desc: 'Music for MINI Russia',
        tags: 'advert, music',
        author: 'Audio Flag, MINI',
      },
      {
        name: 'Porsche Cayenne Coupe Turbo GT',
        link: 'cayenneturbo',
        size: '',
        img: 'cayenne.png',
        url: 'https://newstv.porsche.com/en/article/183824.html',
        desc: 'Music for Porsche Cayenne Coupe Turbo GT',
        tags: 'advert, music',
        author: 'Audio Flag, Porsche',
      },
      // 2021
      {
        name: 'BMW Driving Experience',
        link: 'bmwdriving',
        size: '',
        img: 'bmwdriving.png',
        url: 'https://youtube.com/embed/xtr86z_C3rs',
        desc: '',
        tags: 'music',
        author: 'BMW, Audio Flag',
      },
      {
        name: 'PPR',
        link: 'ppr2020',
        size: '',
        img: 'ppr2020.png',
        url: 'https://youtube.com/embed/-jAxTY0ZQYY',
        desc: '',
        tags: 'sound design',
        author: 'Tmotionz, Audio Flag',
      },
      // 2020
      {
        name: 'Showreel 2020',
        link: 'showreel',
        size: '',
        img: 'showreel.png',
        url: 'https://player.vimeo.com/video/478420798',
        desc: 'Audio Flag 2020 Showreel',
        tags: 'showreel',
        author: 'Audio Flag',
      },
      {
        name: 'Revolve',
        link: 'revolve',
        size: '',
        img: 'revolve.png',
        url: 'https://player.vimeo.com/video/667460681',
        // url: 'https://player.vimeo.com/video/428206702',
        desc: 'Sound design & Music for ',
        tags: 'sound design, music',
        author: 'Audio Flag, Henry Yeomans',
      },
      {
        name: 'Churro Bath time',
        link: 'churrowash',
        size: '',
        img: 'churrowash.png',
        url: 'https://youtube.com/embed/J8314qBTIAs',
        desc: '',
        tags: 'sound design',
        author: 'Ghost3dee, Audio Flag',
      },
      {
        name: 'GoCardless',
        link: 'gocardless',
        size: '',
        img: 'gocardless.png',
        url: 'https://player.vimeo.com/video/434754193',
        desc: '',
        tags: 'sound design, music',
        author: 'Inertia Studios, Audio Flag',
      },
      {
        name: 'DSQUARED2 #SS2021',
        link: 'dsquared2',
        size: '',
        img: 'dsquared2.png',
        url: '//vk.com/video_ext.php?oid=-182912309&id=456239171&hash=6a6530417e8fdeda&hd=1',
        desc: 'Music for DSQUARED2 21 Winter-Spring Collection',
        tags: 'music',
        author: 'Audio Flag',
      },
      {
        name: 'LVMH 2020 China',
        link: 'lvmh',
        size: '',
        img: 'lvmh.png',
        url: 'https://www.youtube.com/embed/k9-5UJrNNtk',
        desc: '',
        tags: 'sound design',
        author: 'Audio Flag',
      },
      {
        name: 'Churro the Octopus',
        link: 'churro',
        size: '',
        img: 'churro.png',
        url: 'https://www.youtube.com/embed/G0BuUeFuFAQ',
        desc: '',
        tags: 'sound design',
        author: 'Audio Flag',
      },
      {
        name: 'Me the Customer and I',
        link: 'mecustomer',
        size: '',
        img: 'mecustomer.png',
        url: 'https://www.youtube.com/embed/3L3tTqlogn4',
        desc: '',
        tags: 'sound design, music',
        author: 'Faze Design Studio, Audio Flag',
      },
      {
        name: 'PaulRSX BWM 3er',
        link: 'paulrsx',
        size: '',
        img: 'paulrsx.png',
        url: 'https://www.youtube.com/embed/sIKfm7spZZQ',
        desc: '',
        tags: 'sound design, music',
        author: 'Audio Flag',
      },
      {
        name: 'Hellsplit Arena VR',
        link: 'hellsplit',
        size: '',
        img: 'hellsplit.png',
        url: 'https://www.youtube.com/embed/Hn5xuJwFu1I',
        desc: 'Original Sound Track for Hellsplit Arena VR',
        tags: 'sound design, music, game,',
        author: 'Audio Flag',
      },
      {
        name: 'Coma: Mortuary',
        link: 'coma',
        size: '',
        img: 'coma.png',
        url: 'https://www.youtube.com/embed/gcfEnd7GaVE',
        desc: 'Sound Design and Music',
        tags: 'sound design, music, game,',
        author: 'Audio Flag',
      },
      {
        name: 'Outdrive',
        link: 'outdrive',
        size: '',
        img: 'outdrive.png',
        url: 'https://www.youtube.com/embed/djcnP3EWhXA',
        desc: '',
        tags: 'sound design, music, game,',
        author: 'Audio Flag',
      },
      {
        name: 'Saint Petersburg WHSD',
        link: 'whsd',
        size: '',
        img: 'whsd.png',
        url: 'https://player.vimeo.com/video/219720945',
        desc: '',
        tags: 'music',
        author: 'Audio Flag',
      },
      {
        name: 'Olymp Trade',
        link: 'olymp',
        size: '',
        img: 'olymp.png',
        url: 'https://player.vimeo.com/video/240497353',
        desc: '',
        tags: 'music, sound design,',
        author: 'Audio Flag',
      },
      {
        name: 'Griffith University',
        link: 'griffith',
        size: '',
        img: 'griffith.png',
        url: 'https://www.youtube.com/embed/a5EcZM-kOlo',
        desc: '',
        tags: 'music',
        author: 'Audio Flag',
      },
    ],
    satisfying: [
      // Alfie Motion
      {
        name: 'Merry Christmas',
        link: 'amxmas',
        size: '',
        img: 'amxmas.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Alfie Motion, Audio Flag',
      },
      {
        name: 'Make Sound Design Great Again',
        link: 'amtrump',
        size: '',
        img: 'amtrump.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Burnt Toast, Alfie Motion, Audio Flag',
      },
      {
        name: 'Portrait',
        link: 'amportait',
        size: '',
        img: 'amportait.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Alfie Motion, Audio Flag',
      },
      {
        name: 'Premium Burger',
        link: 'amburger',
        size: '',
        img: 'amburger.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Alfie Motion, Daniel Mackey, Audio Flag',
      },
      
      // Late Night Render
      {
        name: 'Cylinders',
        link: 'latenightcyl',
        size: '',
        img: 'latenightcyl.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Latenightrender, Audio Flag',
      },
      {
        name: 'Golf',
        link: 'latenightgolf',
        size: '',
        img: 'latenightgolf.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Latenightrender, Audio Flag',
      },
      {
        name: 'Marbles',
        link: 'latenightmarbles',
        size: '',
        img: 'latenightmarbles.png',
        url: '',
        desc: '',
        tags: 'sound design, music',
        author: 'Latenightrender, Audio Flag',
      },
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
    company: state => state.company,
    samplepacks: state => state.samplepacks,
    works: state => state.works,
  }
})
