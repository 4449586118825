import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Packs from '../views/Packs.vue'
import Portfolio from '../views/Portfolio.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    meta: { transitionName: 'slide' }
  },
  {
    path: '/packs',
    name: 'Sample Packs',
    component: Packs,
    meta: { transitionName: 'slide' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: { transitionName: 'slide' }
  },
  {
    path: '/portfolio/:id',
    name: 'Portfolio',
    component: Portfolio,
    meta: { transitionName: 'slide' }
  },
]

const router = new VueRouter({
  mode: 'abstract',
  routes
})

export default router
